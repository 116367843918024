<template>
  <div class="public">
    <div class="container HomePage">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="idiot-child-top">
          <div class="idiot-child-top-box">
            <div v-for="(item, i) in newforCodeData" :key="i">
              <div :class="`idiot-child-top-pic pic${i + 1}`"></div>
              <div class="idiot-child-top-name">{{ liuchengdata[i].name }}</div>
              <div class="idiot-child-top-num">
                {{ item.supervisionNum
                }}<span class="idiot-child-top-num-span">{{ "件" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box-idiot">
          <div class="box" v-for="(item, i) in newforCodeData" :key="i">
            <div class="box-one">
              <span>较昨日：</span>
              <span>{{ item.yesterdayNum }}</span>
              <span
                ><i
                  :class="
                    item.yesterdayNum > 0
                      ? 'el-icon-top t'
                      : item.yesterdayNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-one">
              <span>较上周：</span>
              <span>{{ item.weekNum }}</span>
              <span
                ><i
                  :class="
                    item.weekNum > 0
                      ? 'el-icon-top t'
                      : item.weekNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-one">
              <span>较上月：</span>
              <span>{{ item.monthNum }}</span>
              <span
                ><i
                  :class="
                    item.monthNum > 0
                      ? 'el-icon-top t'
                      : item.monthNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-one">
              <span>较上年：</span>
              <span>{{ item.yearNum }}</span>
              <span
                ><i
                  :class="
                    item.yearNum > 0
                      ? 'el-icon-top t'
                      : item.yearNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
          </div>
        </div>
      </div>
      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
            ref="baidumap"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 预付码新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管新增变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <lineChart
            :chartData="lineChartData1"
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="[
              '应急处理',
              '执法检查',
              '投诉处理',
              '依法处置',
              '责令整改',
              '纠纷处理',
              '其他',
            ]"
            :units="['件', '件', '件', '件', '件', '件', '件']"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio1"
          ></lineChart>
        </div>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管累计变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>

        <div class="charts">
          <lineChart
            :chartData="lineChartData2"
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="[
              '应急处理',
              '执法检查',
              '投诉处理',
              '依法处置',
              '责令整改',
              '纠纷处理',
              '其他',
            ]"
            :units="['件', '件', '件', '件', '件', '件', '件']"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio2"
          ></lineChart>
        </div>
      </div>
      <!-- 预付码按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管区域分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              sortable
              width="290"
            >
            </el-table-column>
            <el-table-column prop="num" label="应急处理" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum1 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="执法检查" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum2 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="投诉处理" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum3 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="依法处置" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum4 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="责令整改" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum5 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="纠纷处理" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum6 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="其他" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum7 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 预付码按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付码领域/行业分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="industryName" label="领域/行业" sortable>
            </el-table-column>
            <el-table-column prop="num" label="应急处理" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num1 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="执法检查" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num2 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="投诉处理" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num3 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="依法处置" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num4 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="责令整改" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num5 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="纠纷处理" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num6 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="其他" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num7 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import Pmap from "@/components/charts/map.vue"; //地图
import Baidumap from "@/components/baidumap/index.vue";
import {
  compositeSuperviseStatistics,
  compositeSuperviseDistribution,
  compositeSuperviseAdd,
  compositeSuperviseCumulative,
  compositeSuperviseCityStatistics,
  compositeSuperviseIndustry,
} from "@/api/homeSynthesizeSupervise.js";
import { cardType } from "@/api/common";

export default {
  name: "HomePage",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
    change: [Number],
  },
  data() {
    return {
      showmap: true,
      showbmap: true,
      lineChart1: false,
      lineChart2: false,
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      radio1: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData1: {
        xAxisData: [],
        seriesData: [],
      },
      radio2: "1",
      lineChartData2: {
        xAxisData: [],
        seriesData: [],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
      liuchengdata: [
        {
          name: "应急处理",
          value: "0",
          com: "件",
        },
        {
          name: "纠纷处理",
          value: "0",
          com: "件",
        },
        {
          name: "投诉处理",
          value: "0",
          com: "件",
        },
        {
          name: "责令整改",
          value: "0",
          com: "件",
        },
        {
          name: "执法检查",
          value: "0",
          com: "件",
        },
        {
          name: "依法处置",
          value: "0",
          com: "件",
        },
        {
          name: "其他",
          value: "0",
          com: "件",
        },
      ],
      newforCodeData: [
        {
          supervisionNum: 0,
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          supervisionNum: 0,
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          supervisionNum: 0,
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          supervisionNum: 0,
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          supervisionNum: 0,
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          supervisionNum: 0,
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          supervisionNum: 0,
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
      ],
      cerditStatistic: [],
      citylistname: "中国",
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        change: this.change,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        console.log("HomePage======", val);
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.compositeSuperviseAdd();
    },
    radio2: function (val) {
      this.compositeSuperviseCumulative();
    },

    hairpinType1: function (val) {
      this.compositeSuperviseCityStatistics();
    },
    hairpinType2: function (val) {
      this.compositeSuperviseIndustry();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  methods: {
    intData() {
      this.cardType();
      this.compositeSuperviseAdd();
      this.compositeSuperviseCumulative();
      this.compositeSuperviseCityStatistics();
      this.compositeSuperviseIndustry();
      this.compositeSuperviseStatistics();
      this.getMapOptionData();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap = true;
      this.showmap = true;
      compositeSuperviseDistribution({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          // console.log("商户区域分布情况:", res);
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: 0,
              data: {
                warning: item.supervisionNum1,
                fine: item.supervisionNum2,
                illegalincome: item.supervisionNum3,
                production: item.supervisionNum4,
                license: item.supervisionNum5,
                detention: item.supervisionNum6,
                other: item.supervisionNum7,
              },
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }

          // console.log(this.mapOptions, "ditu");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    compositeSuperviseStatistics() {
      compositeSuperviseStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //this.forCodeData = res.data;
          // let arr = []
          // arr[0] = res.data[0]
          // arr[1] = res.data[1]
          // arr[2] = res.data[2]
          // arr[3] = res.data[3]
          // arr[4] = res.data[4]
          this.newforCodeData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    compositeSuperviseAdd() {
      this.lineChart1 = true;
      compositeSuperviseAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      })
        .then((res) => {
          //console.log('预付码新增变化趋势',res)
          this.lineChart1 = false;
          if (res.data != null) {
            this.lineChartData1 = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "应急处理",
                  data: res.data.supervisionNum1List,
                },
                {
                  name: "纠纷处理",
                  data: res.data.supervisionNum2List,
                },
                {
                  name: "投诉处理",
                  data: res.data.supervisionNum3List,
                },
                {
                  name: "责令整改",
                  data: res.data.supervisionNum4List,
                },
                {
                  name: "执法检查",
                  data: res.data.supervisionNum5List,
                },
                {
                  name: "依法处置",
                  data: res.data.supervisionNum6List,
                },
                {
                  name: "其他",
                  data: res.data.supervisionNum7List,
                },
              ],
            };
          } else {
            this.lineChartData1 = {
              xAxisData: [],
              seriesData: [],
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    compositeSuperviseCumulative() {
      this.lineChart2 = true;
      compositeSuperviseCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      })
        .then((res) => {
          //console.log('预付码累计变化趋势',res)
          this.lineChart2 = false;
          let data = [];
          if (res.data != null) {
            this.lineChartData2 = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "应急处理",
                  data: res.data.supervisionNum1List,
                },
                {
                  name: "纠纷处理",
                  data: res.data.supervisionNum2List,
                },
                {
                  name: "投诉处理",
                  data: res.data.supervisionNum3List,
                },
                {
                  name: "责令整改",
                  data: res.data.supervisionNum4List,
                },
                {
                  name: "执法检查",
                  data: res.data.supervisionNum5List,
                },
                {
                  name: "依法处置",
                  data: res.data.supervisionNum6List,
                },
                {
                  name: "其他",
                  data: res.data.supervisionNum7List,
                },
              ],
            };
          } else {
            this.lineChartData2 = {
              xAxisData: [],
              seriesData: [],
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    compositeSuperviseCityStatistics() {
      this.loading1 = true;
      compositeSuperviseCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        // console.log('预付码按城市/地区统计',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data || [];
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      compositeSuperviseCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data || []);
      });
    },
    compositeSuperviseIndustry() {
      this.loading2 = true;
      compositeSuperviseIndustry({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data || [];
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      compositeSuperviseIndustry({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  mounted() {
    // console.log(sessionStorage.getItem("initParams"))
    if (sessionStorage.getItem("initParams") === "init") {
      console.log(123123);
      this.intData();
    }

    // this.intData();
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  components: {
    LineChart,
    Pmap,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomePage {
  .tab-content {
    margin: 0 30px 20px 30px;
  }
  // 四色码
  .idiot-one {
    height: 314px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
      to bottom,
      rgba(43, 202, 255, 0.1),
      #0000,
      rgba(43, 202, 255, 0.1)
    );
    .idiot-child-top {
      width: 100%;
      height: 160px;

      .idiot-child-top-box {
        width: 1064px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 40px;
        .idiot-child-top-pic {
          width: 49px;
          height: 52px;
          margin: 26px auto 2px;
          &.pic1 {
            background: url("../assets/imgs/homeSynthesizeSupervise/icon1.png")
              no-repeat;
          }
          &.pic2 {
            background: url("../assets/imgs/homeSynthesizeSupervise/icon2.png")
              no-repeat;
          }
          &.pic3 {
            background: url("../assets/imgs/homeSynthesizeSupervise/icon3.png")
              no-repeat;
          }
          &.pic4 {
            background: url("../assets/imgs/homeSynthesizeSupervise/icon4.png")
              no-repeat;
          }
          &.pic5 {
            background: url("../assets/imgs/homeSynthesizeSupervise/icon5.png")
              no-repeat;
          }
          &.pic6 {
            background: url("../assets/imgs/homeSynthesizeSupervise/icon6.png")
              no-repeat;
          }
          &.pic7 {
            background: url("../assets/imgs/homeSynthesizeSupervise/icon7.png")
              no-repeat;
          }
        }
        .idiot-child-top-name {
          margin: 0 auto 12px;
          width: 73px;
          height: 24px;
          font-size: 14px;
          color: #00c6ff;
          text-align: center;
          line-height: 24px;
          background: url("../assets/imgs/homeSynthesizeSupervise/icon-bg.png")
            no-repeat;
        }
        .idiot-child-top-num {
          width: 120px;
          height: 100%;
          background-image: -webkit-linear-gradient(bottom, #2285ff, #13caff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          line-height: 1;
          .idiot-child-top-num-span {
            font-size: 14px;
          }
        }
      }
    }
    .box-idiot {
      display: flex;
      justify-content: space-around;
    }
    .box {
      width: 16.6%;
      text-align: center;
      border: 0;
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
          #0000,
          #0000,
          #17688d,
          #17688d,
          #0000,
          #0000
        )
        30 30;

      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
