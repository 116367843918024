import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 综合监管概况统计
 */
export const compositeSuperviseStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/compositeSupervise/compositeSuperviseStatistics',
        params
    })
}

/**
 * 综合监管区域分布
 */
export const compositeSuperviseDistribution = (params) => {
    return $api.get({
        url: baseURL + '/api/compositeSupervise/compositeSuperviseDistribution',
        params
    })
}


/**
 * 商户区域分布情况
 */
export const regionalDistributionInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/regionalDistributionInfo',
        params
    })
}

/**
 * 综合监管新增趋势
 */
export const compositeSuperviseAdd = (params) => {
    return $api.get({
        url: baseURL + '/api/compositeSupervise/compositeSuperviseAdd',
        params
    })
}

/**
 * 综合监管累计趋势
 */
export const compositeSuperviseCumulative = (params) => {
    return $api.get({
        url: baseURL + '/api/compositeSupervise/compositeSuperviseCumulative',
        params
    })
}

/**
 * 综合监管按照城市统计
 */
export const compositeSuperviseCityStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/compositeSupervise/compositeSuperviseCityStatistics',
        params
    })
}

/**
 * 综合监管按行业统计
 */
export const compositeSuperviseIndustry = (params) => {
    return $api.post({
        url: baseURL + '/api/compositeSupervise/compositeSuperviseIndustry',
        params
    })
}

